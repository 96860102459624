










function mainFunction(){
 
 var ham_menu = $('.ham_menu')
 var mobile_menu = $('.mobile_menu')
 var close = $('.close')
 var menu_two = $('.menu_two')
 var mobileMenuContent = $('.mobile_menu .content')
 var back = $('.back');
 var home = $('.home');
 var resources =$('.resources');
 var strike = $('.strike_info');
 var scholarships = $('.scholarship');
 var regulations = $('.regulations');
 var heading = $('.heading');
 var about = $('.about');
 var aside = $('aside')
 var minutes = $('.minutes')
 var exec = $('.exec')
 const dropdown_resouces = $('.dropdown_resources')
 var all_links = $('a')


 const mobile_view = window.matchMedia('(max-width:900px)')




function MenuShow(){
  mobile_menu.css({
    'top':0,
    'opacity':1,
    'visibility':'visible'
  })
}

function closeMenu(){
  mobile_menu.css({
    'top':'-50%',
    'opacity':0,
    'visibility':'hidden'
  })
}

function MenuTwo(){
  mobileMenuContent.css({
    "transform":`translateX(-50%)`
  })
}

function MainMenu(){
  mobileMenuContent.css({
    "transform":`translateX(0%)`
  })
}

function MembersResources(){
  location.replace('/index.html')
  //alert()
}

function Resources(){
  heading.text('COVID-19 Virus Info')
}

function Strike(){
  heading.text('Strike Info')
}

function Scholarships(){
  heading.text('Scholarships')
}

function Regulations(){
  heading.text('Regulations')
}

function Minutes(){
  heading.text('Minutes')
}

function About(){
  location.replace('/about.html')
}

function Exec(){
  location.replace('/exec_list.html')
}

function Home(){
  location.replace('/index.html')
}

if(mobile_view.matches){
  dropdown_resouces.click(function(){
    aside.toggleClass('aside_dropdown')
  })

  strike.add(scholarships).add(regulations).add(resources).add(minutes).click(function(){
    aside.removeClass('aside_dropdown')

   
  })

  all_links.attr('target','_self')
 
}



 ham_menu.click(MenuShow)
 close.click(closeMenu)
 menu_two.click(MenuTwo)
 back.click(MainMenu)
 home.click(MembersResources)
 resources.click(Resources);
 strike.click(Strike)
 scholarships.click(Scholarships)
 regulations.click(Regulations) 
 about.click(About)
 minutes.click(Minutes)
 home.click(Home)
 exec.click(Exec)




    
}

try {
  $(document).ready(mainFunction)
} catch (error) {
  
}

import mail_icon from './images/mail.png'
import exec_icon from './images/exec_icon@2x.png'
const member_list_array = [
  {
    name:'Madeline Lee',
    position:'President',
    email: 'madelinel@ceiu30851.com'
  },
  {
    name:'Keith Thomson',
    position:'Vice President (1st)',
    email: 'keitht@ceiu30851.com'
  },
  {
    name:'Jade Houle-Auclair',
    position:'Vice President (2nd)',
    email: 'jadea@ceiu30851.com'
  },
  {
    name:'Charmaine Nelson',
    position:'Secretary',
    email: 'charmainen@ceiu30851.com'
  },

  {
    name:'Fraser Porter Ness',
    position:'Treasurer',
    email: 'fraserpn@ceiu30851.com'
  },
 
  {
    name:'Gabriel Mortotsi',
    position:'IT Lead',
    email: 'gabrielm@ceiu30851.com'
  },
  {
    name:'Stephen Mercier',
    position:'Lead Shop Steward',
    email: 'stephenm@ceiu30851.com'
  },
  {
    name:'Richelle Crook',
    position:'Steward- CDCP Call Centre',
    email: 'richellec@ceiu30851.com'
  },
  {
    name:'Riley Ellis',
    position:'Site representative for the call centres (at Canada Place)',
    email: 'rileye@ceiu30851.com'
  },
  {
    name:'Brenda Todd',
    position:'Women’s Priorities Representative',
    email: 'brendat@ceiu30851.com'
  },
  {
    name:'Christian Leung',
    position:'Person With Disabilities Representative',
    email: 'christianl@ceiu30851.com'
  },
  {
    name:'Carlene Payette',
    position:'Youth Representative and Chair of the Youth Committee',
    email: 'carlenep@ceiu30851.com'
  },
  {
    name:'Karim Somji',
    position:'Visible Minority Representative',
    email: 'karim@ceiu30851.com'
  },
  {
    name:'Hayley Hodge',
    position:'Hermitage Site Representative',
    email: 'hayleyh@ceiu30851.com'
  },
  {
    name:'Lena Poloway',
    position:'Site Representative FMC',
    email: 'lenap@ceiu30851.com'
  },
  {
    name:'Sean Daley',
    position:'Roper Ridge Site Representative',
    email: 'seand@ceiu30851.com'
  },
  {
    name:'Omar Ahmed',
    position:'Milbourne Site Representative',
    email: 'omara@ceiu30851.com'
  },

]

try {
  const list_container = document.querySelector('.list_container')
  member_list_array.forEach(items=>{
  const exec_container = document.createElement('div')
  exec_container.classList='executives'
  const template = `
    
        <div class='exec_name'>
        ${items.name}
        <p>${items.position}</p>
        </div>
        <div class='exec_position'>${items.position}</div>
        <div class='exec_email'>
        <img src="${mail_icon}" alt="mail">
        <a href="mailto:${items.email}">
        ${items.email}
        </div>
    
  `
  exec_container.innerHTML = template
  list_container.appendChild(exec_container)

})
  
} catch (error) {
  
}





